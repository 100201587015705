$(function () {
	$("#tab-all").on("keydown", function (event) {
		if (event.keyCode === 13 && !$(event.target).is("select, textarea")) {
			if (!$(event.target).is('[data-direction="next"]') && !$(event.target).is('[data-direction="previous"]') && !$(event.target).hasClass("er-submit")) {
				event.preventDefault();
			}
		}
	});
	const sourceCountry = $('.source-country');
	const uaeId = "#{uae_id}"

	if (sourceCountry.val() == uaeId) {
		accountValuesForUAE()
	} else {

		accountValues()
	}
	$('#entity_registration_is_agent, #entity_registration_is_correspondent').on('change', function () {
		if (sourceCountry.val() == uaeId) {
			accountValuesForUAE()
		} else {
			accountValues();
		}
	});

	sourceCountry.on('change', function () {
		if ($(this).val() == uaeId) {
			accountValuesForUAE();
		} else {
			accountValues();
		}
	});

	function accountValues() {
		const accountTypeSelect = $('.account-type');
		const isAgentChecked = $('#entity_registration_is_agent').prop('checked');
		const isCorrespondentChecked = $('#entity_registration_is_correspondent').prop('checked');
		accountTypeSelect.empty();
		if (isAgentChecked && isCorrespondentChecked) {
			accountTypeSelect.append(
				'<option value="agent_inside">Agent Inside</option>' +
				'<option value="agent_outside">Agent Outside</option>' +
				'<option value="bank_inside">Bank Inside</option>' +
				'<option value="bank_outside">Bank Outside</option>'
			);
		} else if (isAgentChecked) {
			accountTypeSelect.append(
				'<option value="agent_inside">Agent Inside</option>' +
				'<option value="agent_outside">Agent Outside</option>'
			);
		} else if (isCorrespondentChecked) {
			accountTypeSelect.append(
				'<option value="bank_inside">Bank Inside</option>' +
				'<option value="bank_outside">Bank Outside</option>'
			);
		}

	}

	function accountValuesForUAE() {
		const accountTypeSelect = $('.account-type');
		const isAgentChecked = $('#entity_registration_is_agent').prop('checked');
		const isCorrespondentChecked = $('#entity_registration_is_correspondent').prop('checked');
		accountTypeSelect.empty();
		if (isAgentChecked && isCorrespondentChecked) {
			accountTypeSelect.append(
				'<option value="agent_inside">Agent Inside</option>' +
				'<option value="bank_inside">Bank Inside</option>'
			);
		} else if (isAgentChecked) {
			accountTypeSelect.append(
				'<option value="agent_inside">Agent Inside</option>'
			);
		} else if (isCorrespondentChecked) {
			accountTypeSelect.append(
				'<option value="bank_inside">Bank Inside</option>'
			);
		}
	}
});

$(".account-dropdown").on("change", function () {
	if ($(this).val()) {
		const selectedText = $(this).find("option:selected").text();
		const selectedName = selectedText.split("|")[0].trim();
		const selectNo = $(this).val();
		$(".coa-no").val(selectNo).attr("readonly", true);
		$(".coa-name").val(selectedName).attr("readonly", true);
	} else {
		$(".coa-no, .coa-name").val('').attr('readonly', false)
	}
})

function populateDropDown(id, options) {
	const dropdown = $("#" + id);
	dropdown.empty().html("<option value=''>Please select</option>");

	options.forEach(function (option) {
		const optionValue = option.id;
		const optionText = option.name;
		dropdown.append($('<option>', {
			value: optionValue,
			text: optionText
		}));
	});
}

$(".source-country").on('change', function () {
	const sourceCountry = $(this).val();

	$.ajax({
		type: "GET",
		url: window.location.pathname,
		dataType: "json",
		data: { local_country: sourceCountry },
		error: function (xhr, status, error) {
			const e = "AJAX Error: " + status + error;
			console.error(e);
			reject(e);
		},
		success: function (response) {
			const localCurrencies = response.local_currencies;

			if (Array.isArray(localCurrencies) && localCurrencies.length > 0) {
				populateDropDown("local-currency", localCurrencies);
			} else {
				console.error("No local currencies found or data format is incorrect.");
				populateDropDown("local-currency", []);
			}
		}
	});
});


$(document).ready(function () {
	toggleTrnxHourse();
	$('#entity_registration_is_agent').change(function () {
		toggleTrnxHourse();
	});
	function toggleTrnxHourse() {
		if ($('#entity_registration_is_agent').is(':checked')) {
			$('.trx-hours, #agent_fields_container').show();
		} else {
			$('.trx-hours, #agent_fields_container').hide();
		}
	}
});